class Constants {
    static URL_BASE_APIGATEWAY = "";

    static URL_BASE_AUTHETICATE = "";

    static URL_KEYCLOAK_SERVICE = "https://keycloak.transitsuite.com";

    static URL_VEHICLE_SERVICE = "https://back.transitsuite.com";

    static URL_USER_SERVICE = "https://back.transitsuite.com";

    static URL_ISSUES_SERVICE = "https://back.transitsuite.com";

    static URL_TIMESERIES_SERVICE = "https://back.transitsuite.com";

    static URL_ASSIGNMENT_SERVICE = "https://back.transitsuite.com";

    static URL_FUEL_SERVICE = "https://back.transitsuite.com";

    static URL_REMIND_SERVICE = "https://back.transitsuite.com";

    static URL_BUDGET_SERVICE = "https://back.transitsuite.com";

    static URL_PAYMENT_SERVICE = "https://back.transitsuite.com";

    static URL_INVENTORY_SERVICE = "https://back.transitsuite.com";

    static URL_TRACKER_SERVICE = "https://tracker.transitsuite.com";

    static URL_REPORT_SERVICE = "https://back.transitsuite.com/reports";

    static URL_DASHBOARD_SERVICE = "https://back.transitsuite.com/dashboard";

    static URL_FREIGHT_SERVICE = "https://back.transitsuite.com";

    static URL_KEYCLOAK_TOKEN_ENDPOINT =
        "/auth/realms/fleetrealm/protocol/openid-connect/token";

    static URL_KEYCLOAK_USERINFO_ENDPOINT =
        "/auth/realms/fleetrealm/protocol/openid-connect/userinfo";

    static URL_VEHICLE_ENDPOINT = "/vehicle";

    static URL_VEHICLE_FILTERED_ENDPOINT = "/vehicle-filtered";

    static URL_VEHICLE_ELASTICSEARCH_ENDPOINT = "/elasticvehiclesearch/";

    static URL_VEHICLIMAGE_ENDPOINT = "/vehicleimage/";

    static URL_VEHICLIMAGE_UNCLOSED_ENDPOINT = "/vehicleimage";

    static URL_USER_ENDPOINT = "/user";

    static URL_USER_OF_ROLES_ENDPOINT = "/usersofroles";

    static URL_USER_OF_ROLES_FILTERED_ENDPOINT = "/usersofroles-filtered";

    static URL_USER_ROLES_ENDPOINT = "/usersroles";

    static URL_USER_DEACTIVATE_ENDPOINT = "/user/deactivate";

    static URL_USER_ACTIVATE_ENDPOINT = "/user/activate";

    static URL_USER_LOCAL_INFO_ENDPOINT = "/userlocalinfo";

    static URL_USER_CHANGE_PWD_ENDPOINT = "/users/change-password";

    static URL_USER_ELASTICSEARCH_ENDPOINT = "/elasticusersearch/";

    static URL_DRIVER_ELASTICSEARCH_ENDPOINT = "/elasticdriversearch/";

    static URL_USERIMAGE_ENDPOINT = "/userimage/";

    static URL_USERIMAGE_UNCLOSED_ENDPOINT = "/userimage";

    static URL_COMPANY_CONFIG_ENDPOINT = "/company-configurations";

    static URL_ISSUEJUSTIMAGE_CLOSED_ENDPOINT = "/image/";

    static URL_ISSUEFIRSTIMAGE_ENDPOINT = "/firstimage/";

    static URL_ISSUE_UNCLOSED_ENDPOINT = "/issue";

    static URL_ISSUE_CLOSED_ENDPOINT = "/issue/";

    static URL_ISSUE_UPDATE_STATUS_ENDPOINT = "/issue/updatestatus";

    static URL_ISSUEFILTERED_CLOSED_ENDPOINT = "/issuemessage/";

    static URL_ISSUEFILTERED_UNCLOSED_ENDPOINT = "/issue-filtered";

    static URL_ISSUEIMAGE_CLOSED_ENDPOINT = "/issueimage/";

    static URL_ISSUEIMAGE_UNCLOSED_ENDPOINT = "/issueimage";

    static URL_ASSIGNMNET_ENDPOINT = "/assignment";

    static URL_ASSIGNMNET_RENTAL_STAUS_UPDATE_ENDPOINT = "/updaterentalstatus";

    static URL_CALCULATE_FARE_ENDPOINT = "/calculateFare";

    static URL_ASSIGNMNET_CITY_ENDPOINT = "/cities";

    static URL_ASSIGNMENTVEHICLE_ENDPOINT = "/vehicleassignment/";

    static URL_ASSIGNMENTSTATUS_ENDPOINT = "/assignmentstatus/";

    static URL_UPDATE_ASSIGNEE_ENDPOINT = "/booking-assignee";

    static URL_FUEL_ENDPOINT = "/fuel";

    static URL_FUEL_FILTERED_LIST = "/fuel-entries";

    static URL_FUELVAHICLE_ENDPOINT = "/vehiclefuel/";

    static URL_REMIND_ENDPOINT = "/remind";

    static URL_REMIND_FILTERED_LIST = "/remind-filtered";

    static URL_REMINDVEHICLE_ENDPOINT = "/vehicleremind/";

    static URL_BUDGET_ENDPOINT = "/budget";

    static URL_BUDGET_FILTERED_LIST = "/budget-filtered";

    static URL_PAYMENT_ENDPOINT = "/payment";

    static URL_PAYMENT_FILTERED_LIST = "/payment-filtered";

    static URL_PAYMENT_SETTLE_ENDPOINT = "/paymentsettle";

    static URL_BUDGETVEHICLE_ENDPOINT = "/vehiclebudget/";

    static URL_TIMESERIESSENDER_ENDPOINT = "/timeseriessender/";

    static URL_REPORT_ENDPOINT = "/reports";

    static URL_INVENTORY_ENDPOINT = "/inventory";

    static URL_INVENTORY_FILTERED_LIST = "/inventory-filtered";

    static URL_INVENTORY_CATEGORY_ENDPOINT = "/inventorycategory";

    static URL_INVENTORY_STORAGE_ENDPOINT = "/storageplace";

    static URL_INVENTORY_MOVE_ENDPOINT = "/moveinventory";

    static URL_INVENTORY_MOVE_FILTERED_LIST = "/moveinventory-filtered";

    static URL_INVENTORY_ELASTICSEARCH_ENDPOINT = "/elasticinventorysearch/";

    static URL_INVENTORY_STORAGE_ELASTICSEARCH_ENDPOINT =
        "/elasticstoragesearch/";

    static URL_REPORT_FUEL = "/fuel-consumption";

    static URL_REPORT_BOOKING = "/bookings";

    static URL_REPORT_BUDGET = "/budget";

    static URL_REPORT_REMINDER = "/reminder";

    static URL_REPORT_CUSTOMER = "/customer";

    static URL_REPORT_ISSUE = "/issues";

    static URL_REPORT_VEHICLE = "/vehicle";

    static URL_REPORT_INVENTORY = "/inventory";

    static URL_BOOKING_USER_SEARCH = "/bookingusersearch";

    static URL_BOOKING_FILTERED_LIST = "/booking-filtered";

    static URL_BOOKING_USER_ENDPOINT = "/bookinguser";

    static URL_BOOKINGUSER_FILTERED_LIST = "/bookinguser-filtered";

    static URL_BOOKINGUSER_DEACTIVATE = "/user/deactivate";

    static URL_BOOKINGUSER_ACTIVATE = "/user/activate";

    static URL_BOOKING_CITIES = "/cities";

    static URL_INFO_STORAGEPLACE = "/storageplace-filtered";

    static URL_VEHICLE_TYPE_ENDPOINT = "/vehicletype";

    static URL_VEHICLIE_TYPE_IMAGE_ENDPOINT = "/vehicletypeimage/";

    static URL_VEHICLIE_TYPE_IMAGE_UNCLOSED_ENDPOINT = "/vehicletypeimage/";

    static URL_INFO_VEHICLE_TYPE = "/vehicletype-filtered";

    static URL_ALL_VEHICLE_TYPE = "/vehicletype-all";

    static URL_INFO_COMPANY_PRICING = "/companypricingconfig";

    static URL_INFO_CAR_PRICING = "/carpricingconfig";

    static URL_DASHBOARD_COUNTS_ENDPOINT = "/dashboard/counts";

    static URL_DASHBOARD_BOOKINGSERIES_ENDPOINT = "/dashboard/bookingseries";

    static URL_DASHBOARD_BUDGET_COUNT_ENDPOINT = "/dashboard/budgetcounts";

    static URL_DASHBOARD_DRIVER_COUNT_ENDPOINT = "/dashboard/drivercounts";

    static URL_DASHBOARD_VEHICLE_BOOKING_ENDPOINT =
        "/dashboard/vehiclebookingcounts";

    static URL_DASHBOARD_RECENT_ISSUES_ENDPOINT = "/dashboard/lastissues";

    static URL_DASHBOARD_RECENT_FUEL_ENDPOINT = "/dashboard/lastfuel";

    static URL_DEVICEDATA_ENDPOINT = "/devices/devicedata";

    static URL_LATEST_DEVICEDATA_ENDPOINT = "/devices/devicedata/latest";

    static URL_FREIGHT_UNITS_ENDPOINT = "/freightunits";

    static URL_FREIGHT_ENDPOINT = "/freight";

    static URL_LATEST_DEVICE_RANGE_DATA_ENDPOINT = "/devices/devicedata/range";

    static URL_ALL_DEVICE_DATA_ENDPOINT = "/devices/devicedatacache/alllatest";
}
export { Constants };
