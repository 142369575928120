/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PaymentState {
    refreshKey: number;
}

const initialState: PaymentState = {
    refreshKey: 0,
};

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        refreshPayments: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshPayments } = paymentSlice.actions;

export default paymentSlice.reducer;
